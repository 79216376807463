import React from 'react';
import '../styles/pages/_communication-platform.scss';
import seoPage from '../../content/seo/communication-platform.yml';
import logo from '../styles/assets/images/communication-platform/Communication-Platform.svg';
import Intro from '../components/sections/intro/Intro';
import Button from '../components/buttons/Button';
import RedButton from '../components/buttons/RedButton';
import Title from '../components/title/Title';
import Features from '../components/sections/features/Features';
import FeaturesItem, { FeaturesItemProps } from '../components/sections/features/FeaturesItem';
import { CardContainer } from '../components/card/Card';
import ZoomImage from '../components/zoom/ZoomImage';
import Benefits, { BenefitsList } from '../components/sections/benefits/Benefits';
import BenefitsItem, { BenefitsItemProps } from '../components/sections/benefits/BenefitsItem';
import Standards from '../components/sections/standards/Standards';
import BlogPost, { BlogPostProps } from '../components/sections/blog/BlogPost';
import BlogPostList from '../components/sections/blog/BlogPostList';
import Cases from '../components/sections/cases/Cases';
import CaseItem, { CaseItemProps } from '../components/sections/cases/CasesItem';
import Pricing from '../components/sections/pricing/Pricing';

// Images
import MessIcon from '../styles/assets/images/communication-platform/messaging.svg';
import VoiceIcon from '../styles/assets/images/communication-platform/voice.svg';
import SecIcon from '../styles/assets/images/communication-platform/security.svg';
import DevIcon from '../styles/assets/images/communication-platform/development.svg';
import ChatIcon from '../styles/assets/images/communication-platform/chat.svg';
import IdentIcon from '../styles/assets/images/communication-platform/identities.svg';
import IotIcon from '../styles/assets/images/communication-platform/iot.svg';
import DedicatedIcon from '../styles/assets/images/communication-platform/dedicated.svg';

import schemeImg from '../styles/assets/images/communication-platform/scheme.png';
import schemeImg2x from '../styles/assets/images/communication-platform/scheme@2x.png';

import IconHealth from '../styles/assets/images/cases/Icon-Health.svg';
import IconFin from '../styles/assets/images/cases/Icon-Fin.svg';
import IconAuto from '../styles/assets/images/cases/Icon-Auto.svg';
import IconCloud from '../styles/assets/images/cases/Icon-Cloud.svg';
import IconIoT from '../styles/assets/images/cases/Icon-IoT.svg';
import IconIndustry from '../styles/assets/images/cases/Icon-Industry.svg';
import Layout from 'src/layout/Layout';

const FEATURES: FeaturesItemProps[] = [
	{
		iconSrc: MessIcon,
		title: 'Messaging',
		text: 'Direct and group chats, file transfer, synchronized conversation history',
	},
	{
		iconSrc: VoiceIcon,
		title: 'Voice & Video Calls',
		text: 'Real-time calling capabilities within web, desktop, and mobile',
	},
	{
		iconSrc: SecIcon,
		title: 'Security',
		text: 'End-to-end encryption with Post-quantum cryptography support',
	},
	{
		iconSrc: DevIcon,
		title: 'Development',
		text: 'Platform APIs and SDKs enable integration for multiple platforms and IoT',
	},
	{
		iconSrc: ChatIcon,
		title: 'Chat features',
		text: 'Media messages, read receipts, push notifications, etc.',
	},
	{
		iconSrc: IdentIcon,
		title: 'Identities',
		text: 'Integration with existing Identity Providers to authenticate and discover users',
	},
	{
		iconSrc: IotIcon,
		title: 'IoT',
		text: 'Providing, emulators, SDKs, and libraries to integrate with the Platform',
	},
	{
		iconSrc: DedicatedIcon,
		title: 'Dedicated',
		text: 'Use our Cloud Platform or set up the Platform in your infrastructure',
	},
];

const BENEFITS: BenefitsItemProps[] = [
	{
		title: 'End-to-end data encryption',
		children:
			'Virgil end-to-end security is based on the Double Ratchet Algorithm, that encrypts each message and push notification separately, therefore new session keys cannot be used to compromise past session keys.',
	},
	{
		title: 'Secure data and files sharing',
		children:
			'To end-to-end secure data sharing, we provide Virgil key management system (KMS) that allows generating a specific encryption key for certain data and share this key in encrypted way with data owner.',
	},
	{
		title: 'Password protection against hacking',
		children:
			'We apply password-hardened encryption to all stored users’ passwords based on the PHE protocol, therefore solve security issues with weak passwords.',
	},
	{
		title: 'Post-compromise protection for stored data',
		children:
			'By using the PHE protocol for data at rest, platform provides protection of users’ data even if the database has been compromised.',
	},
	{
		title: 'Post-quantum cryptography support',
		children:
			'Virgil provides you with the combined post-quantum encryption based on Falcon (encryption) and Round5 (signature) algorithms.',
	},
	{
		title: 'Trusted contacts',
		children:
			'The platform interacts with Virgil PKI to identify, store and verify the identity of who you’re chatting with.',
	},
];

const E3KIT_POSTS: BlogPostProps[] = [
	{
		title: 'Post-compromise security with Virgil PureKit and MariaDB',
		meta: 'Rebecca Yarbrough — February 2nd, 2020',
		img:
			'/images/posts/Post-Compromise-Security-for-MariaDB-with-Virgil-Security-PureKit-700x265-c-default.jpeg',
		href: '/blog/purekit-mariadb-plugin/',
	},
	{
		title:
			'Marriott’s $123 million mistake: \n A two-step technical guide to avoiding costly GDPR fines',
		meta: 'Rebecca Yarbrough – December 5th, 2019',
		img: '/images/posts/GDPR-Marriott-Breach-Blog-Post-Header-700x265-c-default.jpg',
		href: '/blog/marriott-gdpr-fine/',
	},
	{
		title: 'Virgil Security has been selected as a Big50-2019 Startup',
		meta: 'Rebecca Yarbrough — October 10th, 2019',
		img: '/images/posts/Big50-2019-Cover-e1570583274284-700x265-c-default.png',
		href: '/blog/big50-2019/',
	},
];

const ALL_CASES: CaseItemProps[] = [
	{
		title: 'Healthcare',
		description: 'Make your communication platform and backend HIPAA-compliant.',
		icon: IconHealth,
	},
	{
		title: 'Financial',
		description:
			'Manage cardholder data and personal financial information within a PCI DSS-compliant system.',
		icon: IconFin,
	},
	{
		title: 'Automotive',
		description: 'Build IEEE-1609.2-compliant security solutions for protecting V2X communication.',
		icon: IconAuto,
	},
	{
		title: 'Cloud',
		description: 'Protect and manage access to sensitive data in the сloud storage.',
		icon: IconCloud,
	},
	{
		title: 'Internet of Things',
		description: 'Secure IoT devices from manufacturing to the end-user experience.',
		icon: IconIoT,
	},
	{
		title: 'Industry 4.0',
		description:
			'Build a fully protected industrial ecosystem and securely manage it across the stack.',
		icon: IconIndustry,
	},
];

const CommunicationPlatformPage: React.FC = () => {
	return (
		<Layout seoPage={seoPage} className="communication-platform">
			<Intro>
				<img src={logo} alt="communication-platform logo" />
				<h1>Secure Communications Platform</h1>
				<p>Protect your company communications, intellectual property and privacy</p>
				<Button>Coming soon</Button>
			</Intro>
			<Features>
				<Title>
					<h2>Platform features</h2>
					<p>At least 8 reasons to choose Virgil Communication platform.</p>
				</Title>
				<CardContainer>
					{FEATURES.map((fc, index) => {
						return <FeaturesItem {...fc} key={index} />;
					})}
				</CardContainer>
				<RedButton href="https://virgilsecurity.com/contact/">CONTACT US FOR DETAILS</RedButton>
			</Features>
			<section className="instruction">
				<div className="wrapper">
					<Title>
						<h2>How it works</h2>
						<p>
							Communication platform provides end-to-end security for any participants from IoT to
							chatbots.
						</p>
					</Title>
					<ZoomImage src={schemeImg2x} disableDesktop={true}>
						<img
							className="instruction-img"
							alt="Communication platform provides end-to-end security for any participants from IoT to chatbots."
							src={schemeImg}
							srcSet={schemeImg2x}
						/>
					</ZoomImage>
				</div>
			</section>
			<Benefits>
				<Title className="dark">
					<h2>Security features</h2>
					<p>Your data is always end-to-end protected and controlled by you.</p>
				</Title>
				<BenefitsList>
					{BENEFITS.map((bn, index) => (
						<BenefitsItem {...bn} index={index} key={index} />
					))}
				</BenefitsList>
			</Benefits>
			<Standards />
			<Pricing>
				<Title className="dark">
					<h2>Pricing</h2>
					<p>Choose your own subscription plan based on your needs.</p>
				</Title>
			</Pricing>
			<BlogPostList>
				{E3KIT_POSTS.map((post, index) => (
					<BlogPost {...post} key={index} />
				))}
			</BlogPostList>
			<section className="we-believe">
				<div className="wrapper">
					<Title>
						<h2>
							We believe privacy is a fundamental human right. <br />
							With our products, stay protected anywhere everywhere anytime.
						</h2>
					</Title>
				</div>
			</section>
			<Cases>
				{ALL_CASES.map((item, index) => (
					<CaseItem {...item} key={index} />
				))}
			</Cases>
		</Layout>
	);
};

export default CommunicationPlatformPage;
