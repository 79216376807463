import React from 'react';
import WhiteButton from '../../buttons/WhiteButton';
import classnames from 'classnames';
import PricingTableCell, { PricingCell } from './PricingTableCell';

type PricingHeader = {
	package: string;
	price: string;
	text?: string;
	link: string;
	linkText: string;
};

export interface RowProps {
	name: string;
	available: PricingCell[];
}

export interface PricingTableProps {
	headers: PricingHeader[];
	rows: RowProps[];
}

const DesktopTable: React.FC<PricingTableProps> = ({ headers, rows }) => {
	return (
		<table className="pricing-table">
			<thead>
				<tr>
					<th scope="col" />
					{headers.map((el, index) => {
						return (
							<th key={index}>
								<div className="header-col">
									<span className="title">{el.package}</span>
									<span className="price">{el.price}</span>
									{el.text && <span className="text">{el.text}</span>}
									<WhiteButton href={el.link}>{el.linkText}</WhiteButton>
								</div>
							</th>
						);
					})}
				</tr>
			</thead>
			<tbody>
				{rows.map((el, index) => {
					return (
						<tr key={index}>
							<th className="header-row">{el.name}</th>
							{el.available.map((av, i) => {
								return (
									<th key={i}>
										<PricingTableCell cell={av} />
									</th>
								);
							})}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

const MobileTable: React.FC<PricingTableProps> = ({ headers, rows }) => {
	return (
		<ul className="pricing-mobile">
			{headers.map((el, index) => {
				return (
					<li key={index}>
						<div className="header-col">
							<span className="title">{el.package}</span>
							<span className="price">{el.price}</span>
							{el.text && <span className="text">{el.text}</span>}
							<WhiteButton href={el.link}>{el.linkText}</WhiteButton>
						</div>
						<div className="row-container">
							{rows.map((row, ind) => (
								<div
									className={classnames('row-element', { active: row.available[index] })}
									key={ind}
								>
									<p className="table-col-title">{row.name}</p>
									<PricingTableCell cell={row.available[index]} />
								</div>
							))}
						</div>
					</li>
				);
			})}
		</ul>
	);
};

const PricingTable: React.FC<PricingTableProps> = props => {
	return (
		<>
			<DesktopTable {...props} />
			<MobileTable {...props} />
		</>
	);
};

export default PricingTable;
