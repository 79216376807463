import React from 'react';
import PricingTable, { PricingTableProps } from './PricingTable';

const PRICING: PricingTableProps = {
	headers: [
		{
			package: 'Personal',
			price: '$1 per user annually',
			text: 'Bundle discount available:\n$11K per 100K users annually',
			link: 'https://virgilsecurity.com/contact/',
			linkText: 'Contact us',
		},
		{
			package: 'Personal+',
			price: '$5 per user annually',
			text: 'Bundle discount available:\n$55K per 100K users annually',
			link: 'https://virgilsecurity.com/contact/',
			linkText: 'Contact us',
		},
		{
			package: 'Enterprise',
			price: '$10K per 100k users\nmonthly',
			link: 'https://virgilsecurity.com/contact/',
			linkText: 'Contact us',
		},
	],
	rows: [
		{
			name: 'Platform security features (post-quantum, double ratchet, etc)',
			available: [true, true, true],
		},
		{
			name: 'In-App Messaging',
			available: [{ text: '1GB', subtext: '~20M chat messages' }, 'Unlimited', 'Unlimited'],
		},
		{
			name: 'In-App Voice and Video calls',
			available: [
				{ text: '10K calls', subtext: 'total calls per month' },
				'Unlimited',
				'Unlimited',
			],
		},
		{
			name: 'User authentication requests',
			available: ['Unlimited', 'Unlimited', 'Unlimited'],
		},
		{
			name: 'User contact storage & discovery',
			available: ['Unlimited', 'Unlimited', 'Unlimited'],
		},
		{
			name: 'User identity verification requests',
			available: ['Unlimited', 'Unlimited', 'Unlimited'],
		},
		{
			name: 'Transferring media content',
			available: [{ text: '30GB', subtext: '~15K high-res photos' }, 'Unlimited', 'Unlimited'],
		},
		{
			name: 'Media content storage',
			available: [{ text: '5GB', subtext: '~2.5K high-res photos' }, 'Unlimited', 'Unlimited'],
		},
		{
			name: 'Storage operations',
			available: [
				{ text: '2,100,000 ops', subtext: 'uploads & downloads' },
				'Unlimited',
				'Unlimited',
			],
		},
		{
			name: 'Individual support',
			available: [false, false, true],
		},
		{
			name: 'Platform backend services on-premise',
			available: [false, false, true],
		},
		{
			name: 'Training on topics cryptography and key management',
			available: [false, false, true],
		},
		{
			name: 'Country-specific data location',
			available: [false, false, true],
		},
		{
			name: 'Business Associate Agreement',
			available: [false, false, true],
		},
		{
			name: 'Unlimited capacity and global low latency',
			available: [false, false, true],
		},
	],
};

const Pricing: React.FC = ({ children }) => {
	return (
		<section className="pricing">
			<div className="wrapper">
				{children}
				<PricingTable {...PRICING} />
			</div>
		</section>
	);
};

export default Pricing;
