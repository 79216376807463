import React from 'react';
import { ReactComponent as CheckIcon } from '../../../styles/assets/images/ui/check-true.svg';
import { ReactComponent as MinusIcon } from '../../../styles/assets/images/ui/icon-minus.svg';

export type PricingCell = boolean | string | { text: string; subtext: string };

export interface PricingCellProps {
	cell: PricingCell;
}

const PricingTableCell: React.FC<PricingCellProps> = ({ cell }) => {
	if (typeof cell === 'string') {
		return <>{cell}</>;
	} else if (typeof cell === 'object') {
		return (
			<>
				<p className="table-col-text">{cell.text}</p>
				<p className="table-col-subtext">{cell.subtext}</p>
			</>
		);
	} else {
		return cell ? <CheckIcon /> : <MinusIcon />;
	}
};

export default PricingTableCell;
